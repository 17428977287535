import React from "react"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import "./materials.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ShowMoreText from 'react-show-more-text';
import { window } from "browser-monads"
import Img from "gatsby-image"


export default function Appliances() {
  const data = useStaticQuery(graphql`
  query {
    smegImageOne: file(relativePath: { eq: "smeg/smeg_1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageTwo: file(relativePath: { eq: "smeg/smeg_2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageThree: file(relativePath: { eq: "smeg/smeg_3.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageFour: file(relativePath: { eq: "smeg/smeg_4.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smegImageFive: file(relativePath: { eq: "smeg/smeg_5.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageSix: file(relativePath: { eq: "smeg/smeg_6.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageEight: file(relativePath: { eq: "smeg/smeg_8.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageNine: file(relativePath: { eq: "smeg/smeg_9.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageTen: file(relativePath: { eq: "smeg/smeg_10.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageEleven: file(relativePath: { eq: "smeg/smeg_11.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageTwelve: file(relativePath: { eq: "smeg/smeg_12.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageThirteen: file(relativePath: { eq: "smeg/smeg_13.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageFourteen: file(relativePath: { eq: "smeg/smeg_14.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smegImageFifteen: file(relativePath: { eq: "smeg/smeg_15.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    neffImageOne: file(relativePath: { eq: "neff/neff_1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      neffImageTwo: file(relativePath: { eq: "neff/neff_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      neffImageThree: file(relativePath: { eq: "neff/neff_3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      neffImageFour: file(relativePath: { eq: "neff/neff_4.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      neffImageFive: file(relativePath: { eq: "neff/neff_5.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gaggenauImageOne: file(relativePath: { eq: "gaggenau/gaggenau_1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gaggenauImageTwo: file(relativePath: { eq: "gaggenau/gaggenau_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gaggenauImageThree: file(relativePath: { eq: "gaggenau/gaggenau_3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gaggenauImageFour: file(relativePath: { eq: "gaggenau/gaggenau_4.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gaggenauImageFive: file(relativePath: { eq: "gaggenau/gaggenau_5.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      omoikiriImageOne: file(relativePath: { eq: "omoikiri/omoikiri_1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      omoikiriImageTwo: file(relativePath: { eq: "omoikiri/omoikiri_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      omoikiriImageThree: file(relativePath: { eq: "omoikiri/omoikiri_3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      omoikiriImageFour: file(relativePath: { eq: "omoikiri/omoikiri_4.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      omoikiriImageFive: file(relativePath: { eq: "omoikiri/omoikiri_5.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blancoImageOne: file(relativePath: { eq: "blanco/blanco_1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blancoImageTwo: file(relativePath: { eq: "blanco/blanco_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blancoImageThree: file(relativePath: { eq: "blanco/blanco_3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blancoImageFour: file(relativePath: { eq: "blanco/blanco_4.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blancoImageFive: file(relativePath: { eq: "blanco/blanco_5.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schockImageOne: file(relativePath: { eq: "schock/schock_1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schockImageTwo: file(relativePath: { eq: "schock/schock_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schockImageThree: file(relativePath: { eq: "schock/schock_3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schockImageFour: file(relativePath: { eq: "schock/schock_4.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schockImageFive: file(relativePath: { eq: "schock/schock_5.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
  `)
  return (

    <Layout>
      <SEO
        title="Варианты техники"
        keywords={["Встраиваемая техника для кухни", "Мойки и смесители", "Техника на сайте Империя студия мебели", "Варианты техники на сайте Империя студия мебели", "Техника для кухни"]}
        description="Варианты техники для кухни от производителей Blanco, Gaggenau, Neff, Оmoikiri, Schock, Smeg - встраиваемая техника, мойки и сместиели."
      />
      <h1 className="text-center">
        Варианты техники по производителям
    </h1>
      <Container>

        <div className="card-group vgr-cards">
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={true} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.smegImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.smegImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                  <Img fluid={data.smegImageThree.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                  <Img fluid={data.smegImageFour.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageFive.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageSix.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageEight.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageNine.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageTen.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageEleven.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageTwelve.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageThirteen.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageFourteen.childImageSharp.fluid} />
                </div>
                <div>
                  <Img fluid={data.smegImageFifteen.childImageSharp.fluid} />
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <h4 className="card-title w-75">SMEG</h4>
              <ShowMoreText
                /* Default options */
                lines={10}
                more='Читать дальше'
                less='Скрыть текст'
                anchorClass='text-dark underline-text'
                expanded={false}
              >
                <p className="card-text">Smeg&nbsp;&mdash; это итальянский производитель бытовой техники, головной офис которого находится в&nbsp;городе Гуасталла на&nbsp;севере Италии в&nbsp;провинции Реджо Эмилья.</p>
                <p> В&nbsp;сертификационных лабораториях Smeg стремятся разрабатывать решения, отвечающие требованиям современной жизни, опираясь на&nbsp;опыт компании и&nbsp;самые передовые технологии. Благодаря строгому процессу проектирования, вся продукция Smeg отличается своим качеством, надежностью, безопасностью, гибкостью в&nbsp;использовании и&nbsp;эргономичностью. Постоянный контроль и&nbsp;тестирование в&nbsp;процессе производства позволяют гарантировать абсолютную надежность.</p>
                <p>Кроме того, Smeg уделяет особое внимание простоте и&nbsp;удобству использования продукции, благодаря различным программным решениям и&nbsp;интуитивным интерфейсам, также обеспечена возможность интеллектуального управления энергопотреблением.</p></ShowMoreText>
            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={true} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.neffImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.neffImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                  <Img fluid={data.neffImageThree.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                  <Img fluid={data.neffImageFour.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 4</p> */}
                </div>
                <div>
                  <Img fluid={data.neffImageFive.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 5</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <h4 className="card-title w-75">NEFF</h4>
              <ShowMoreText
                /* Default options */
                lines={8}
                more='Читать дальше'
                less='Скрыть текст'
                anchorClass='text-dark underline-text'
                expanded={false}
              >
                <p>Немецкий премиальный бренд NEFF придерживается высочайших стандартов надежности и&nbsp;функциональности. Перед выводом на&nbsp;рынок любого продукта все компоненты от&nbsp;сборки до&nbsp;упаковки проходят сложные испытания, гарантирующие бесперебойную работу приборов в&nbsp;течение всего срока их&nbsp;службы. </p>
                <p className="card-text">Кулинарное искусство вдохновляет людей, а&nbsp;люди вдохновляют NEFF. Это основополагающий принцип для всего, что создает NEFF. Ничто не&nbsp;должно мешать вашему кулинарному творчеству.

С&nbsp;такими уникальными технологиями, как полностью утапливаемая в&nbsp;основание духового шкафа дверца SLIDE&amp;HIDE<sup class="reg">&reg;</sup>, система нагрева CircoTherm<sup class="reg">&reg;</sup>, технология удобного регулирования мощности варочной панели TwistPad<sup class="reg">&reg;</sup>, вы&nbsp;сможете почувствовать себя настоящим профессионалом у&nbsp;себя дома.</p>
              </ShowMoreText>
            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={true} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.gaggenauImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.gaggenauImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                  <Img fluid={data.gaggenauImageThree.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                  <Img fluid={data.gaggenauImageFour.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 4</p> */}
                </div>
                <div>
                  <Img fluid={data.gaggenauImageFive.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 5</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <h4 className="card-title w-75">Gaggenau</h4>
              <ShowMoreText
                /* Default options */
                lines={8}
                more='Читать дальше'
                less='Скрыть текст'
                anchorClass='text-dark underline-text'
                expanded={false}
              >
                <p>Компания по&nbsp;производству бытовой техники премиум-класса из&nbsp;Германии.</p>
                <p> Основное отличие в&nbsp;подходе к&nbsp;производству бренда Gaggenau&nbsp;&mdash; на&nbsp;каждом этапе производства бытовой техники мастер рассматривает свою работу и&nbsp;в&nbsp;поиске несовершенств все проверяет руками.</p>
                <p> Компания производит духовые шкафы, варочные панели, вытяжки, холодильники и&nbsp;другие кухонные приборы.</p>
              </ShowMoreText>
            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={true} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.omoikiriImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.omoikiriImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                  <Img fluid={data.omoikiriImageThree.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                  <Img fluid={data.omoikiriImageFour.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 4</p> */}
                </div>
                <div>
                  <Img fluid={data.omoikiriImageFive.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 5</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <h4 className="card-title w-75">OMOIKIRI</h4>
              <ShowMoreText
                /* Default options */
                lines={8}
                more='Читать дальше'
                less='Скрыть текст'
                anchorClass='text-dark underline-text'
                expanded={false}
              >
                <p>Японская марка OMOIKIRI&nbsp;&mdash; производит смесители, мойки, фильтры и&nbsp;измельчители. Продукция компании станет настоящим украшением любого кухонного интерьера: от&nbsp;простого и&nbsp;непритязательного до&nbsp;ретро и&nbsp;ультрасовременного.</p>
                <p> Главный принцип дизайнерского решения&nbsp;&mdash; превращение обыденных предметов кухни в&nbsp;произведение искусства. Главный принцип производства продукции&nbsp;&mdash; надежность и&nbsp;удобство в&nbsp;эксплуатации.</p>
              </ShowMoreText>
            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={true} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.blancoImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.blancoImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                  <Img fluid={data.blancoImageThree.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                  <Img fluid={data.blancoImageFour.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 4</p> */}
                </div>
                <div>
                  <Img fluid={data.blancoImageFive.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 5</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <h4 className="card-title w-75">BLANCO</h4>
              <ShowMoreText
                /* Default options */
                lines={8}
                more='Читать дальше'
                less='Скрыть текст'
                anchorClass='text-dark underline-text'
                expanded={false}
              >
                <p>Компания BLANCO является частью немецкого холдинга Blanc &amp;&nbsp;Fischer Familienholding GmbH и&nbsp;производит мойки и&nbsp;смесители.</p>
                <p> &laquo;Лучшее качество на&nbsp;каждом участке работы работы&raquo;&nbsp;&mdash; так кратко можно сформулировать философию BLANCO.</p>
                <p> Специалисты компании постоянно занимаются разработкой новой продукции и&nbsp;внедрением инноваций и&nbsp;особое внимание уделяют дизайну во&nbsp;всех его мельчайших деталях.</p>
              </ShowMoreText>
            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">

              <Carousel showThumbs={false} dynamicHeight={true} autoPlay={true} infiniteLoop={true}>
                <div>
                  <Img fluid={data.schockImageOne.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                  <Img fluid={data.schockImageTwo.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                  <Img fluid={data.schockImageThree.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                  <Img fluid={data.schockImageFour.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 4</p> */}
                </div>
                <div>
                  <Img fluid={data.schockImageFive.childImageSharp.fluid} />
                  {/* <p className="legend">Legend 5</p> */}
                </div>
              </Carousel>

            </div>
            <div className="card-body">
              <h4 className="card-title w-75">SCHOCK</h4>
              <ShowMoreText
                /* Default options */
                lines={8}
                more='Читать дальше'
                less='Скрыть текст'
                anchorClass='text-dark underline-text'
                expanded={false}
              >
                <p>Немецкая фирма SCHOCK производит мойки, смесители и кухонные аксессуары. Более 40 лет опыта в ручном производстве моек, компания имеет собственные патенты в этой отрасли, а также тесно сотрудничала на протяжении своей истории с фирмой BLANCO.</p>
              </ShowMoreText>
            </div>
          </div>
        </div>

      </Container>
    </Layout>
  )

}